<script>
import { defineComponent } from "vue";

import EditorJS from "@editorjs/editorjs";

import Header from "@editorjs/header";
import Paragraph from "@editorjs/paragraph";
import SimpleImage from "@editorjs/simple-image";
import List from "@editorjs/list";
import Quote from "@editorjs/quote";
import Checklist from "@editorjs/checklist";
import Marker from "@editorjs/marker";
import Delimiter from "@editorjs/delimiter";
import InlineCode from "@editorjs/inline-code";
import LinkTool from "@editorjs/link";
import Embed from "@editorjs/embed";
import Table from "@editorjs/table";
import Attaches from "@editorjs/attaches";

import Swal from "@/plugins/swal";

import { useErrors } from "@/compositions/useErrors";

import AutoComplete from "@/components/autocomplete/AutoComplete";
import Calendar from "@/components/calendar/Calendar";

import { mapActions, mapGetters } from "vuex";

const ProjectModel = {
  name: "",
  description: "",
  budget: null,
  estimated_amount: null,
  started_at: null,
  ended_at: null,
  skills: null,
  type: null
};

export default defineComponent({
  name: "ProjectCreate",
  components: { AutoComplete, Calendar },
  setup() {
    const errors = useErrors();
    return {
      ...errors
    };
  },
  data() {
    return {
      errors: {},
      loading: false,
      editor: null,
      filteredSkills: null,
      types: [],
      project: JSON.parse(JSON.stringify(ProjectModel))
    };
  },
  computed: {
    ...mapGetters("skill", ["skillList"]),
    currentType() {
      return this.types[0];
    }
  },
  async created() {
    try {
      const { data } = await this.getTypes();
      this.types = data;
    } catch (e) {
      console.log(e);
    }
  },
  mounted() {
    this.editor = new EditorJS({
      holder: "editor",
      placeholder: "Let`s write an awesome story!",
      /**
       * Available Tools list.
       * Pass Tool's class or Settings object for each Tool you want to use
       */
      tools: {
        /**
         * Each Tool is a Plugin. Pass them via 'class' option with necessary settings {@link docs/tools.md}
         */
        header: {
          class: Header,
          inlineToolbar: ["link"],
          config: {
            placeholder: "Header"
          },
          shortcut: "CMD+SHIFT+H"
        },
        paragraph: {
          class: Paragraph
        },
        /**
         * Or pass class directly without any configuration
         */
        image: {
          class: SimpleImage,
          inlineToolbar: ["link"]
        },
        list: {
          class: List,
          inlineToolbar: true,
          shortcut: "CMD+SHIFT+L"
        },
        checklist: {
          class: Checklist,
          inlineToolbar: true
        },
        quote: {
          class: Quote,
          inlineToolbar: true,
          config: {
            quotePlaceholder: "Enter a quote",
            captionPlaceholder: "Quote's author"
          },
          shortcut: "CMD+SHIFT+O"
        },
        marker: {
          class: Marker,
          shortcut: "CMD+SHIFT+M"
        },
        delimiter: Delimiter,
        inlineCode: {
          class: InlineCode,
          shortcut: "CMD+SHIFT+C"
        },
        linkTool: LinkTool,
        embed: Embed,
        table: {
          class: Table,
          inlineToolbar: true,
          shortcut: "CMD+ALT+T"
        },
        attaches: {
          class: Attaches,
          config: {
            endpoint: "http://localhost:8008/uploadFile"
          }
        }
      },

      /**
       * Previously saved data that should be rendered
       */
      data: {}
    });
  },
  methods: {
    ...mapActions("project", ["projectStore"]),
    ...mapActions("projectType", ["getTypes"]),
    ...mapActions("skill", ["getSkillList"]),
    searchSkill(event) {
      this.getSkillList(event.query);
    },
    async create() {
      this.errorSet();
      this.loading = true;

      try {
        const outputData = await this.editor.save();

        try {
          let project = this.project;
          project.description = outputData;

          const data = await this.projectStore(project);

          Swal.fire({
            title: "Success!",
            text: "Project created successfully!",
            icon: "success",
            confirmButtonText: "Cool"
          });

          this.project = JSON.parse(JSON.stringify(ProjectModel));
          this.loading = false;
          await this.$router.push({
            name: "dashboard.client.project.edit",
            params: { id: data.id }
          });
        } catch (e) {
          this.errorSet(e.errors);
          this.loading = false;
        }
      } catch (e) {
        console.log("Saving failed: ", e);
      }
    }
  }
});
</script>

<template>
  <div class="intro-y flex flex-row items-center justify-between  mt-8">
    <h2 class="text-lg font-medium">
      Project create
    </h2>
    <base-button
      :loading="loading"
      @click="create"
    >
      Create
    </base-button>
  </div>
  <div class="grid grid-cols-12 gap-6 mt-5">
    <div class="intro-y col-span-12 lg:col-span-8">
      <!-- BEGIN: Form Layout -->
      <div class="intro-y box p-2 md:p-5">
        <div :class="{ 'has-error': errorHas('name') }">
          <label class="font-medium">Project name</label>
          <input
            v-model="project.name"
            type="text"
            class="input w-full border mt-2"
            placeholder="Project name"
          >
          <span
            v-if="errorHas('name')"
            class="pristine-error text-theme-6 mt-2"
          >
            {{ errorGet("name") }}
          </span>
        </div>
        <div
          class="mt-3"
          :class="{ 'has-error': errorHas('skills') }"
        >
          <label class="font-medium">Skills</label>
          <div class="relative mt-2">
            <AutoComplete
              v-model="project.skills"
              class="input"
              field="name"
              :suggestions="skillList"
              :multiple="true"
              @complete="searchSkill($event)"
            />
          </div>
          <span
            v-if="errorHas('skills')"
            class="pristine-error text-theme-6 mt-2"
          >
            {{ errorGet("skills") }}
          </span>
        </div>
        <div
          class="mt-3"
          :class="{ 'has-error': errorHas('description') }"
        >
          <label class="font-medium">Description</label>
          <div class="prose lg:prose-xl max-w-none mt-2">
            <div
              id="editor"
              class="bg-white rounded-lg"
            />
          </div>
          <span
            v-if="errorHas('description')"
            class="pristine-error text-theme-6 mt-2"
          >
            {{ errorGet("description") }}
          </span>
        </div>
      </div>
      <!-- END: Form Layout -->
    </div>
    <div class="intro-y col-span-12 lg:col-span-4">
      <div class="">
        <div :class="{ 'has-error': errorHas('type') }">
          <label class="font-medium">Project type</label>
          <div class="relative mt-2">
            <base-select
              v-if="types.length"
              v-model="project.type"
              :model-value="currentType"
              class="mb-8 w-xs"
              field="value"
              :options="types"
            />
          </div>
          <span
            v-if="errorHas('type')"
            class="pristine-error text-theme-6 mt-2"
          >
            {{ errorGet("status") }}
          </span>
        </div>
      </div>
      <div class="intro-y box p-2 md:p-5">
        <div
          class="mt-3"
          :class="{ 'has-error': errorHas('budget') }"
        >
          <label class="font-medium">Budget</label>
          <div class="relative mt-2">
            <input
              v-model="project.budget"
              type="text"
              class="input pr-12 w-full border col-span-4"
              placeholder="Budget"
            >
            <div
              class="absolute top-0 right-0 rounded-r w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
            >
              $
            </div>
          </div>
          <span
            v-if="errorHas('budget')"
            class="pristine-error text-theme-6 mt-2"
          >
            {{ errorGet("budget") }}
          </span>
        </div>
        <div
          class="mt-3"
          :class="{ 'has-error': errorHas('estimated_amount') }"
        >
          <label class="font-medium">Estimated amount</label>
          <div class="relative mt-2">
            <input
              v-model="project.estimated_amount"
              type="text"
              class="input pr-12 w-full border col-span-4"
              placeholder="Estimated amount"
            >
            <div
              class="absolute top-0 right-0 rounded-r w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
            >
              $
            </div>
          </div>
          <span
            v-if="errorHas('estimated_amount')"
            class="pristine-error text-theme-6 mt-2"
          >
            {{ errorGet("estimated_amount") }}
          </span>
        </div>
        <div
          class="mt-3"
          :class="{ 'has-error': errorHas('started_at') }"
        >
          <label class="font-medium">Start date</label>
          <div class="relative mt-2">
            <Calendar
              v-model="project.started_at"
              class="w-full border"
              date-format="yy-mm-dd"
              :show-time="true"
              hour-format="24"
              :month-navigator="true"
              :year-navigator="true"
              year-range="2020:2030"
              placeholder="Start date"
            />
          </div>
          <span
            v-if="errorHas('started_at')"
            class="pristine-error text-theme-6 mt-2"
          >
            {{ errorGet("started_at") }}
          </span>
        </div>
        <div
          class="mt-3"
          :class="{ 'has-error': errorHas('ended_at') }"
        >
          <label class="font-medium">End date</label>
          <div class="relative mt-2">
            <Calendar
              v-model="project.ended_at"
              class="w-full border"
              :month-navigator="true"
              :year-navigator="true"
              year-range="2020:2030"
              placeholder="End date"
            />
          </div>
          <span
            v-if="errorHas('ended_at')"
            class="pristine-error text-theme-6 mt-2"
          >
            {{ errorGet("ended_at") }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
